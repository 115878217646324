import React, { useState } from 'react';
import styled from 'styled-components';

import { ClinicDirectus } from 'apiFolder/directus-main';

import Seo from 'components/seo';
import Footer from 'components/Footer/Footer';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import ClinicTopPopular from 'components/booking/ClinicTopPopular';
import { ArticleH2 } from 'components/common/Styles';

interface IArticleWithCreators {
	pageContext: {
		dataPopularClinics: ClinicDirectus[];
	};
}

const PopularClinics: React.FC<IArticleWithCreators> = ({ pageContext: { dataPopularClinics } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo title='Popular physiotherapy clinics in Australia' description='' />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<Wrap>
						<TopClinicsWrapper>
							<TopClinicsHeader>
								<ArticleH2>Popular Physiotherapy Clinics in Australia</ArticleH2>
							</TopClinicsHeader>
							{dataPopularClinics.map((clinic, index) => (
								<ClinicTopPopular key={clinic.slug} clinic={clinic} index={index} />
							))}
						</TopClinicsWrapper>
					</Wrap>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopClinicsWrapper = styled.div`
	padding: 0 10px;
`;

const Wrap = styled.div`
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
`;

const TopClinicsHeader = styled.div`
	margin: 80px auto 0;
	border-bottom: 1px solid #e0e0df;
	padding-bottom: 50px;
	@media (max-width: 768px) {
		margin: 40px auto 0;
		padding-bottom: 40px;
		text-align: left;
	}
`;

export default PopularClinics;
